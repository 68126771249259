import React, { useEffect } from 'react';
import styled from 'styled-components';
import QuestionBanded from 'containers/inProgressPage/subcomponents/QuestionBanded';
import QuestionSelect from 'containers/inProgressPage/subcomponents/QuestionSelect';
import QuestionStock from 'containers/inProgressPage/subcomponents/stock/QuestionStock';
import QuestionFileUpload from 'containers/inProgressPage/subcomponents/QuestionFileUpload';
import { QuestionTypes } from 'containers/app/helpers/questionTypes';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { QuestionNumber } from 'containers/inProgressPage/styles/StyledComponents';
import { SET_QUESTION_VALUE } from 'services/context/assessmentContext/assessmentContext';

const SlotQuestion = ({ formikProps }) => {
  const {
    currentSlot: {
      question: { question_type },
      sort_index
    },
    currentSection,
    state: { currentSlotId, questionValue },
    dispatch
  } = useAssessmentContext();
  const { values, setValues } = formikProps;

  useEffect(() => {
    if (values[currentSlotId] === '' || values[currentSlotId]?.length) {
      dispatch({
        type: SET_QUESTION_VALUE,
        payload: values
      });
    } else if (questionValue && questionValue[currentSlotId] !== undefined) {
      setValues(questionValue);
    } else if (values[currentSlotId] !== null) {
      if (values[currentSlotId] instanceof File) {
        dispatch({
          type: SET_QUESTION_VALUE,
          payload: values
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const questionTypes = {
    question_select: <QuestionSelect formikProps={formikProps} />,
    question_banded: <QuestionBanded formikProps={formikProps} />,
    question_stock: <QuestionStock />,
    question_upload: <QuestionFileUpload formikProps={formikProps} />
  };

  const getQuestionNumber = () => {
    const questions = currentSection.section.standard_question_list.map(
      (question, index) => ({ ...question, number: index + 1 })
    );
    const number = questions.find(
      question => question.sort_index === sort_index
    ).number;
    return `Q${number}`;
  };

  const isStockQuestion = question_type === QuestionTypes.STOCK;
  return (
    <QuestionWrapper isStockQuestion={isStockQuestion}>
      <QuestionNumber>{!isStockQuestion && getQuestionNumber()}</QuestionNumber>
      {questionTypes[question_type]}
    </QuestionWrapper>
  );
};

export default SlotQuestion;

const QuestionWrapper = styled.div`
  ${({ isStockQuestion }) =>
    !isStockQuestion &&
    `img {
      max-width: 100%;
    }
    pre{
      background-color: #23241f;
      color: #f8f8f2;
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 5px 10px;
      white-space: pre-wrap;
    }
 `}
`;
