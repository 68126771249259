import * as yup from 'yup';
const ANSWER_LIMIT = Math.pow(10, 10);
// The only schema we have to validate forms is for banded questions
// So non banded questions just have an empty validation object
export const validateForm = (currentSlotId, banded) => {
  if (banded) {
    return yup.object().shape({
      [currentSlotId]: yup
        .mixed()
        .test('isNumber', 'Response must be a number', value => {
          if (value === null || value === undefined) {
            return true;
          }
          if (value.includes('e') || value.includes('E')) {
            return false;
          }
          return !isNaN(value);
        })
        .test(
          'isNoBiggerThanLimit',
          `Response must be smaller than ${ANSWER_LIMIT}`,
          value => {
            if (value < ANSWER_LIMIT) {
              return true;
            }
            return false;
          }
        )
    });
  }
};
