import * as yup from 'yup';
import { takerFieldTypes } from 'containers/app/helpers/takerFieldTypes';
export const takerValidation = fields => {
  // Email regex from: https://emailregex.com/
  // Slightly modified to remove unneeded escaped character
  let schema = {
    email: yup
      .string()
      .strict(false)
      .trim()
      .email('Please enter a valid email')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Please enter a valid email'
      )
      .required('Please enter an email')
  };
  fields.forEach(field => {
    let validation;
    const fieldType = field.field_type;
    if (fieldType === takerFieldTypes.select) {
      validation = yup.object();
    } else if (fieldType === takerFieldTypes.text) {
      validation = yup.string();
    }

    field.is_required && (validation = validation.required('Required field'));
    schema = {
      ...schema,
      [field.content[0].title]: validation
    };
  });
  return yup.object().shape(schema);
};
