import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useInputFocus } from 'components/global/hooks/useInputFocus';
import { useClickedOutsideEvent } from 'services/hooks/useClickedOutsideEvent';
import { useRenderErrors } from 'components/global/hooks/useRenderErrors';
import {
  Input,
  InputLabel,
  InputDescriptor,
  InputWrapper,
  InputHolder,
  InputTitle,
  InputUnderlineInner,
  InputUnderlineOuter,
  Option,
  OptionalLabel,
  DropdownOptions,
  ErrorSpan
} from '../styles/StyledComponents';
import { renderDropdownSvg } from './assets/renderDropdownSvg';

const DropdownFormik = ({
  name,
  label,
  description,
  placeholder,
  disabled,
  options,
  formikProps,
  optional,
  showInitialErrors
}) => {
  const optionTexts = options.map(option => option.text);
  const { focus, onFocus, onBlur } = useInputFocus();
  const [toggle, setToggle] = useState(false);
  const { values, setValues, errors, submitCount } = formikProps;
  const showErrors = useRenderErrors(
    errors,
    submitCount,
    name,
    showInitialErrors
  );
  const backDropRef = useRef(null);
  useClickedOutsideEvent(backDropRef, () => setToggle(false));

  const clickListener = ({ target }) => {
    const isEmptyOption = target.id === 'EMPTY_OPTION';
    if (optionTexts.includes(target.id) || isEmptyOption) {
      const optionValue = isEmptyOption
        ? ''
        : options.find(({ text }) => text === target.id);
      setToggle(false);
      onBlur();
      setValues({
        ...values,
        [name]: optionValue
      });
    }
  };

  const renderChildren = () => {
    const renderOptions = [...options];
    optional && renderOptions.unshift({ id: 'EMPTY_OPTION', text: '' });
    return renderOptions.map(({ id, text }) => {
      const optionId = text || id;
      return (
        <Option id={optionId} key={optionId} onClick={clickListener}>
          {text}
        </Option>
      );
    });
  };

  return (
    <InputWrapper className={disabled && 'disabled'} marginBottom="0">
      <InputTitle>
        {label && <InputLabel>{label}</InputLabel>}
        {optional && <OptionalLabel>(Optional)</OptionalLabel>}
        {description && <InputDescriptor>{description}</InputDescriptor>}
      </InputTitle>
      <InputHolder ref={backDropRef}>
        <StyledIcon>
          {renderDropdownSvg(disabled, setToggle, toggle)}
        </StyledIcon>
        <Input
          className={showErrors && 'error'}
          name={name}
          onFocus={() => {
            onFocus();
            setToggle(true);
          }}
          placeholder={placeholder}
          disabled={disabled}
          value={values[name]?.text || ''}
          readOnly
          role="listbox"
        />
        {toggle && <DropdownOptions>{renderChildren()}</DropdownOptions>}
      </InputHolder>
      <InputUnderlineOuter className={showErrors && 'error'}>
        <InputUnderlineInner
          className={focus ? 'focus' : 'unfocus'}
        ></InputUnderlineInner>
      </InputUnderlineOuter>
      <ErrorSpan>
        {showErrors ? errors[name] : <span>error placeholder</span>}
      </ErrorSpan>
    </InputWrapper>
  );
};

export default DropdownFormik;

DropdownFormik.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optional: PropTypes.bool,
  showInitialErrors: PropTypes.bool.isRequired
};

DropdownFormik.defaultProps = {
  label: '',
  description: '',
  placeholder: 'Select',
  disabled: false,
  optional: true
};

const StyledIcon = styled.span`
  svg {
    background: transparent;
    z-index: 0;
  }
`;
