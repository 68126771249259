import React from 'react';
import Loading from 'components/local/loading/Loading';
import { PageWrapper } from 'components/local/styles/StyledComponents';

const PageLoading = () => {
  return (
    <PageWrapper>
      <div className="container-fluid container-height middle-xs">
        <div className="row">
          <div direction="column" className="col-xs-12">
            <Loading delay={false} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PageLoading;
