import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import LinearBottomNavBar from 'components/local/navbar/LinearBottomNavBar';
import LinkedStockSummary from 'containers/inProgressPage/subcomponents/stock/LinkedStockSummary';
import PageLoading from 'components/local/loading/PageLoading';
import SectionWrapper from 'containers/inProgressPage/subcomponents/SectionWrapper';
import Slot from 'containers/inProgressPage/subcomponents/Slot';
import { beforeUnload } from 'containers/inProgressPage/helpers/beforeUnload';
import { formatAnswer } from 'containers/inProgressPage/helpers/formatAnswer';
import { QuestionTypes } from 'containers/app/helpers/questionTypes';
import { useGlobalEventListener } from 'components/global/hooks/useGlobalEventListener';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { useHandleSlot } from 'containers/inProgressPage/hooks/useHandleSlot';
import { useSetCurrentSlot } from 'containers/inProgressPage/hooks/useSetCurrentSlot';
import { useHandleSlotRequests } from 'containers/inProgressPage/hooks/useHandleSlotRequests';
import { validateForm } from 'containers/inProgressPage/helpers/validateForm';
import { useAssesmentContext } from 'services/context/assessmentContext/assessmentContext';
import { StyledErrorMessage } from '../linear/SectionLinear';

const SectionStock = () => {
  const {
    state: { currentSlotId, slotIsLoading, slotContentIsLoading },
    currentSection,
    currentSlot
  } = useSetCurrentSlot();
  const { handleSubmitSlot } = useHandleRequest();
  const { banded, select } = useHandleSlot(currentSlot);
  const [showSummary, setShowSummary] = useState(false);
  const [fileError, setFileError] = useState(null);
  const formikProps = useFormik({
    initialValues: { [currentSlotId]: banded ? null : [] },
    validationSchema: validateForm(currentSlotId, banded),
    onSubmit: values => {
      console.log(values);
      handleNextSlot(values[currentSlotId]);
    }
  });

  const {
    state: { files }
  } = useAssesmentContext();

  useGlobalEventListener('beforeunload', beforeUnload, window);
  useHandleSlotRequests();

  useEffect(() => {
    if (currentSlot && formikProps) {
      formikProps.setValues({
        [currentSlotId]: banded ? null : []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlot]);

  const handleNextSlot = async value => {
    const fileKey = Object.keys(files)[0];
    const fileValue = files[fileKey] ?? null;

    if (fileValue instanceof File && fileValue.size > 5000000) {
      setFileError('There is an error with your file. File size exceeds 5MB');
      return;
    }

    setFileError(null);
    const answer = await formatAnswer(
      fileValue instanceof File ? fileValue : value,
      currentSlot,
      select,
      banded
    );

    if (answer instanceof Error) {
      setFileError('There was an error uploading the file');
      return;
    }

    handleSubmitSlot(answer);
  };

  if (slotIsLoading || slotContentIsLoading || !currentSlotId)
    return <PageLoading />;

  const { question, parent_slot_attempt_question } = currentSlot;
  let question_type = question?.question_type;
  const parentSlotPlayed = Boolean(
    parent_slot_attempt_question?.answer?.balances?.length
  );

  return (
    <SectionWrapper background="#efeeed" formikProps={formikProps}>
      <form onSubmit={formikProps.handleSubmit}>
        <Slot formikProps={formikProps} />
        {parentSlotPlayed &&
          parent_slot_attempt_question.question.question_type ===
            QuestionTypes.STOCK && (
            <LinkedStockSummary
              data={parent_slot_attempt_question}
              startingBalance={
                parent_slot_attempt_question.question.starting_balance ??
                currentSection.section.starting_balance
              }
              showSummary={showSummary}
              setShowSummary={setShowSummary}
            />
          )}
        {question_type && question_type !== QuestionTypes.STOCK && (
          <LinearBottomNavBar
            background="#dfddda"
            flat={true}
            formikProps={formikProps}
            minimal={true}
            setShowSummary={setShowSummary}
          />
        )}
      </form>
      {fileError && <StyledErrorMessage>{fileError}</StyledErrorMessage>}
    </SectionWrapper>
  );
};

export default SectionStock;
